import React, { Component } from 'react';
import './App.scss';

import {
  isTablet, isBrowser, isMobileOnly
} from "react-device-detect";

import Desktop from './components/Desktop';
import Menu from './components/desktop/Menu';
import {Menu as MenuMobile} from './components/mobile/Menu';
import Page from './components/desktop/Page';
import {Page as PageMobile} from './components/mobile/Page';
import Navigation from './components/desktop/Navigation';
import {Navigation as NavigationMobile} from './components/mobile/Navgation';
import HomeNavigation from './components/desktop/HomeNavigation';
import { HashRouter,Route, Redirect, Switch } from "react-router-dom";
import Mobile from './components/Mobile';
import { Home }  from './components/mobile/Home';
import {once} from 'lodash';


class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      flatItems:[],
      location: 'es',
      menuOpen: false,
      device: "d",
      navigation: {
        currentPageLoaded: false,
        currentPage: 0,
        nextPage: null,
        previousPage: null,
      }
    };
  }


  // pega o array e le o primeiro item, copia os parametros default para cada images item dentro dele para um novo array;
  // adiciona um parametro parent;
  // se tiver filho

  makeFlatNav(arr) {
    let FlatNav = [];
    let accumulator = [];

    const tempArray = arr.map((item, index) => {
      const temp =  this.resolveArrayItem(item, index);
      if(Array.isArray(temp)) {
        temp.map((it) => accumulator = [...accumulator, it])
      } else {
        accumulator = [accumulator, ...temp];
      }
    });


    FlatNav = [FlatNav, ...tempArray];
    //console.log('flatNav', accumulator);
    return accumulator;
  }

  resolveArrayItem(item, index) {
    const hasChildren = !item.leaf;
    const title = item.title;
    const parentIndex = index;
    const id = item.id;
    const noLink = !!item.nolink;
    const mobile = item.mobile;

    let accumulator = [];

    if(item.images === undefined) {
      console.log('images is underfined', item);
      return [];
    }

    const fArray = item.images.map((img, index) => {
      return {
        title: title,
        page: img,
        parent: parentIndex,
        id: id,
        imageId: `${id}-i${index}`,
        noLink: noLink,
        mobile: mobile
      }
    });

    accumulator = [...accumulator, ...fArray];

    if(item.children) {
      const children = this.makeFlatNav(item.children);
      accumulator = [...accumulator, ...children];
      //console.log('children', accumulator);
    }

    return accumulator;
  }


  componentDidMount() {

    document.title = "Latam - Memoria Integrada 2018";

    this.setState({device: this.checkDevice()});

    fetch("./globals/structure.json")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result,
            flatItems: this.makeFlatNav(result)
          });
          // console.log(this.state.flatItems);
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  checkDevice = () => {
    if(isBrowser) {
      return "d";
    }
    if(isTablet) {
      return "t";
    }
    if(isMobileOnly) {
      return "m";
    }
    return "browser";
  };

  handleMenuState = (newState) => {
    console.log('handlemenu state');
    this.setState({menuOpen: newState});
  };

  handleSetCurrentPage = (n) => {
    //console.log('setCurrentPage', n)
    const tempItem = this.state.flatItems.find((obj) => obj.imageId === `${n}`);

    // console.log('temp item no link', tempItem.noLink);
    // console.log("Index Of", this.state.flatItems.indexOf(tempItem));

    const currentIndex = this.state.flatItems.indexOf(tempItem);

    // const currentHash = tempItem.noLink ?  currentIndex + 1 : currentIndex;

    // console.log("compare: ", currentIndex, currentHash);


    if(tempItem.noLink) {
      return
    }


    if(tempItem !== undefined) {
      if(this.state.flatItems.indexOf(tempItem) !== this.state.navigation.currentPage) {
        this.setState({navigation: {currentPage: currentIndex}, menuOpen: false});
      }
    } else {
      this.setState({navigation: {currentPage: 0}, menuOpen: false});
      window.location.hash = `/${this.state.location}/${this.state.device}/0-i0`;
    }
  };

  handleCurrentPage = () => {
    //console.log()
    const currentPageObj = this.state.flatItems[this.state.navigation.currentPage];
    // console.log(currentPageObj);
    return currentPageObj.page;
  };

  handleCurrentMobilePage = () => {
    //console.log()
    const currentPageObj = this.state.flatItems[this.state.navigation.currentPage];
    // console.log(currentPageObj);
    return currentPageObj.mobile;
  };

  handleNextPage = () => {

    const {flatItems, navigation} = this.state;

    const size = flatItems.length;
    // console.log(`cur: ${navigation.currentPage}, size: ${size}`)
    if(navigation.currentPage >= (size - 1) ) {
      return;
    }
    if(flatItems[navigation.currentPage +1].noLink === true) {
      window.location.hash = `/${this.state.location}/${this.state.device}/${flatItems[navigation.currentPage + 2].imageId}`;
    } else {
      window.location.hash = `/${this.state.location}/${this.state.device}/${flatItems[navigation.currentPage + 1].imageId}`;
    }

    // this.setState({navigation: {currentPage: this.state.navigation.currentPage + 1}});window.location.hash = `/${this.state.location}/${this.state.device}/${flatItems[navigation.currentPage + 1].imageId}`;

  };

  getNextMobileItem = (current) => {
    const {flatItems, navigation} = this.state;

    if(flatItems[current + 1].id !== flatItems[current].id) {
      // console.log('diferente', flatItems[current + 1].id, flatItems[current].id);
      return current + 1;
    } else {
      // console.log('igual', flatItems[current + 1].id, flatItems[current].id);
      return this.getNextMobileItem(current + 1);
    }

  };

  getPrevMobileItem = (current) => {
    const {flatItems, navigation} = this.state;

    if(current === 0) {
      return current;
    }

    if(flatItems[current - 1].id !== flatItems[current].id) {
      // console.log('diferente', flatItems[current - 1].id, flatItems[current].id);
      return current - 1;
    } else {
      // console.log('igual', flatItems[current - 1].id, flatItems[current].id);
      return this.getPrevMobileItem(current - 1);
    }

  };

  handleNextMobilePage = () => {

    const {flatItems, navigation} = this.state;

    const size = flatItems.length;
    // console.log(`cur: ${navigation.currentPage}, size: ${size}`)
    // console.log('array: ', flatItems[navigation.currentPage]);
    if(navigation.currentPage >= (size - 1) ) {
      return;
    }

    const nextItem = this.getNextMobileItem(navigation.currentPage);

    // console.log('next will be: ', nextItem);

    if(flatItems[nextItem].noLink === true) {
      window.scrollTo(0,0);
      window.location.hash = `/${this.state.location}/m/${flatItems[nextItem + 1].imageId}`;
    } else {
      window.scrollTo(0,0);
      window.location.hash = `/${this.state.location}/m/${flatItems[nextItem].imageId}`;
    }

    // this.setState({navigation: {currentPage: this.state.navigation.currentPage + 1}});window.location.hash = `/${this.state.location}/${this.state.device}/${flatItems[navigation.currentPage + 1].imageId}`;

  };

  handlePrevPage = () => {

    const {flatItems, navigation} = this.state;

    if(navigation.currentPage === 0) {
      return;
    }

    //console.log(flatItems[navigation.currentPage].noLink)

    if(flatItems[navigation.currentPage -1].noLink === true) {
      // console.log("o no link é true")
      window.scrollTo(0,0);
      window.location.hash = `/${this.state.location}/${this.state.device}/${flatItems[navigation.currentPage - 2].imageId}`;
    } else {
      window.scrollTo(0,0);
      window.location.hash = `/${this.state.location}/${this.state.device}/${flatItems[navigation.currentPage - 1].imageId}`;
    }

    //this.setState({navigation: {currentPage: this.state.navigation.currentPage - 1}});

  };



  handlePrevMobilePage = () => {

    const {flatItems, navigation} = this.state;

    const size = flatItems.length;

    if(navigation.currentPage === 0 ) {
      return;
    }

    const prevItem = this.getPrevMobileItem(navigation.currentPage);

    // console.log('next will be: ', prevItem);

    if(flatItems[prevItem].noLink === true) {
      window.scrollTo(0,0);
      window.location.hash = `/${this.state.location}/m/${flatItems[prevItem - 1].imageId}`;
    } else {
      window.scrollTo(0,0);
      window.location.hash = `/${this.state.location}/m/${flatItems[prevItem].imageId}`;
    }

    // this.setState({navigation: {currentPage: this.state.navigation.currentPage + 1}});window.location.hash = `/${this.state.location}/${this.state.device}/${flatItems[navigation.currentPage + 1].imageId}`;

  };



  resolvePath = ({match}) => {

    console.log("resolving path", match);

    //if(!this.state.isLoaded) return;


    //idioma
    if(match.params.location !== this.state.location) {
      switch (match.params.location) {
        case "es":
          this.setState({location: 'es'});
          break;
        case "pt":
          this.setState({location: 'pt'});
          break;
        case "en":
          this.setState({location: 'en'});
          console.log('location has changed to EN');
          //window.location.hash = `/en/${match.params.device}/${match.params.id}`;
          break;
        default:
          //this.setState({location: 'es'});
          //window.location.hash = `/es/${match.params.device}/${match.params.id}`;/
          break;
      }
    }

    //device
    if(match.params.device !== this.state.device) {

      //console.log("diferente: ", match.param.device, this.state.device);

      switch (match.params.device) {
        case "d":
          this.setState({device: 'd'});
          break;
        case "t":
          this.setState({device: 't'});
          break;
        case "m":
          this.setState({device: 'm'});
          break;
        default:
          //this.setState({device: this.checkDevice()});
          window.location.hash = `/${match.params.location}/${this.checkDevice()}/${match.params.id}`;
          break;
      }
    }

    if(match.params.id && match.params.id !== this.state.navigation.currentPage) {
      this.handleSetCurrentPage(match.params.id);
    }

    const toMobile = once(this.renderMobile);
    const toDesktop = once(this.renderDesktop);

    if(match.params.device !== 'd') {
      return toMobile(match.params.location);
    }
    return toDesktop(match.params.location);

  };

  renderMobile = (myLocation) => (
    <>
      <Mobile>
        <MenuMobile
          structure={this.state.items}
          menuState={this.state.menuOpen}
          setMenuState={this.handleMenuState}
          location={myLocation}
        />
        { this.state.navigation.currentPage > 0 ? (
        <NavigationMobile
          nextPage={this.handleNextMobilePage}
          prevPage={this.handlePrevMobilePage}
          menuState={this.state.menuOpen}
          location={myLocation}
        />): ''}

        { this.state.navigation.currentPage > 0 ?
          <PageMobile files={this.handleCurrentMobilePage()}
                      currentPage={this.state.navigation.currentPage}
                      navigate={this.handleSetCurrentPage}
                      location={myLocation}
          />
          : <Home location={myLocation} />
        }

      </Mobile>
    </>
  );

  renderDesktop = (myLocation) => (
    <>
    <Desktop>
      <Menu
        structure={this.state.items}
        menuState={this.state.menuOpen}
        setMenuState={this.handleMenuState}
        navigate={this.handleSetCurrentPage}
        location={myLocation}
        device={this.state.device}
      />


      { this.state.navigation.currentPage > 0 ? (
        <Navigation
          menuState={this.state.menuOpen}
          setMenuState={this.handleMenuState}
          nextPage={this.handleNextPage}
          prevPage={this.handlePrevPage}
          currentPage={this.state.navigation.currentPage}
          navigate={this.handleSetCurrentPage}
          location={myLocation}
          device={this.state.device}
        /> ) : <HomeNavigation
        menuState={this.state.menuOpen}
        setMenuState={this.handleMenuState}
        nextPage={this.handleNextPage}
        currentPage={this.state.navigation.currentPage}
        navigate={this.handleSetCurrentPage}
      />}


      {console.log('actual location', this.state.location)}
      { this.state.flatItems.length > 0 ?
        <Page file={this.handleCurrentPage()}
              currentPage={this.state.navigation.currentPage}
              navigate={this.handleSetCurrentPage}
              location={myLocation}
        />
        : ''
      }
    </Desktop>
    </>
  );



  render() {

    const currentPage = this.state.navigation.currentPage;
    const {device, location, isLoaded} = this.state;


    return isLoaded ? (
        <div className="App">
          <HashRouter>
            <Switch>
              <Route exact path="/" render={() => (
                  <Redirect to={`/${location}/${this.checkDevice()}`} />
              )}/>
              <Route exact path="/:location/:device/:id?" component={this.resolvePath}/>
            </Switch>


          </HashRouter>
        </div>
    ) : (<div className="App">Loading...</div>);
  }
}

export default App;

import React, {useState, useEffect} from 'react';
import {MobileImage} from './MobileImage';

export  function Home(props) {


  const goToURL = (url) => {
    // console.log('scroll to', url);
    window.scrollTo(0, 0);
    window.location.hash = `/${props.location}/m/${url}`;
  };

  const renderPage = () =>{
      return (
        <div id="home">
          <div>
            <MobileImage image="latam-000-c1.png" location={props.location} />
          </div>
          <div onClick={() => goToURL('1-i0')}>
            <MobileImage image="latam-000-c2.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('4-0-i0')}>
            <MobileImage image="latam-000-c3.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('5-0-i0')}>
            <MobileImage image="latam-000-c4.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('6-0-i0')}>
            <MobileImage image="latam-000-c5.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('7-0-0-i0')}>
            <MobileImage image="latam-000-c6.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('7-1-0-i0')}>
            <MobileImage image="latam-000-c7.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('7-2-0-i0')}>
            <MobileImage image="latam-000-c8.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('8-0-0-i0')}>
            <MobileImage image="latam-000-c9.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('9-0-0-i0')}>
            <MobileImage image="latam-000-c10.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('9-1-0-i0')}>
            <MobileImage image="latam-000-c11.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('9-2-0-i0')}>
            <MobileImage image="latam-000-c12.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('10-0-i0')}>
            <MobileImage image="latam-000-c13.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('11-i0')}>
            <MobileImage image="latam-000-c14.png"  location={props.location} />
          </div>
          <div onClick={() => goToURL('12-0-i0')}>
            <MobileImage image="latam-000-c15.png"  location={props.location} />
          </div>
        </div>
      )

  };

  return(
    <div className="page">
      {renderPage()}
    </div>
  )
}
